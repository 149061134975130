.headerItem:hover {
    background-color: rgba(0,144,207,0.5);
}

.headerItem h3 {
    font-size: 1rem;
}

@media (max-width: 600px) {
    .headerItem h3 {
        font-size: 11px;
    }
}

@media (min-width: 601px) {
    .headerItem h3 {
        font-size: 1rem; 
    }
}