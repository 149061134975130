.footerSetting{
    background-color: rgba(27, 27, 27);
    color: white;
}

.hyLink{
    text-decoration: none;
    color:white
}

.whiteDivider {
    border-color: white;
    width: 70%;
}