.img-hover {
    transition: transform 0.2s; 
    border-radius: 10px;
}

.img-hover:hover {
    transform: scale(1.05); 
}

.content:hover ~ .img-hover {
    transition: transform 0.2s;
    transform: scale(1.05);   
}